import React, { useMemo, useState } from 'react';
import Filters, {
    AllowedFilters,
    AnalyticFilters,
    BaseFilters,
    parseAnalyticFilters,
} from '../Filters/Filters';
import styles from './FrameWithFilters.module.css';
import { Visualization } from '../../../@Types/Metabase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';

interface FrameWithFiltersProps {
    /** Visualization that is going to be displayed */
    visualization: Visualization;
    /** URL that is going to be displayed */
    urlLink: string;
    /** Function called when the iframe has loaded */
    setIframeLoaded: Function;
    /** if app is currently mobile */
    mobile: boolean;
}
function FrameWithFilters({
    mobile,
    visualization,
    urlLink,
    setIframeLoaded,
}: FrameWithFiltersProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [filters, setFilters] = useState<AnalyticFilters>({
        values: {},
        entities: {},
        idOrganization:
            visualization.description?.includes('idorganization') ||
            visualization.description?.includes('idOrganization')
                ? siteInfo.organization?.idOrganization!
                : undefined,
    });

    const allowedFilters: AllowedFilters = useMemo(() => {
        const values = [];
        const entities = [];
        const filters: AllowedFilters = {};
        const descriptionFilters =
            visualization.description
                ?.replace('--filters--', '')
                ?.split('--') ?? [];
        for (const f of descriptionFilters) {
            const filter = f.replace(/(\r\n|\n|\r)/gm, '');
            if (Object.values(BaseFilters).includes(filter as BaseFilters)) {
                filters[filter as BaseFilters] = true;
            } else if (filter.startsWith('ticket.')) {
                const path = filter.split('.');
                if (path[1] === 'values') {
                    values.push(path[2]);
                }
            } else if (filter.startsWith('entities.')) {
                const path = filter.split('.');
                entities.push({
                    idEntity: path[1],
                    multiple: path[2].trim() === 'true',
                });
            }
        }
        if (values.length > 0) filters.values = values;
        if (entities.length > 0) filters.entities = entities;
        return filters;
    }, [visualization]);

    const hiddenFilters: string = useMemo(() => {
        const filters =
            visualization.description
                ?.replace('--filters--', '')
                ?.split('--') ?? [];
        const hidden = [...filters, ...Object.values(BaseFilters)]
            .map((filter) => {
                switch (filter) {
                    case BaseFilters.dates:
                        return 'startDate,endDate';
                    case BaseFilters.companies:
                        return 'idCompanies';
                    case BaseFilters.agents:
                        return 'idAgents';
                    case BaseFilters.agents2:
                        return 'idAgents2';
                    default:
                        return filter;
                }
            })
            .join(',');
        return hidden.toLowerCase() + ',' + hidden;
    }, [visualization]);
    const formatUrl = (): string => {
        let originalUrl =
            urlLink.split('#')[0] +
            ((): string => {
                let originalUrl = parseAnalyticFilters(
                    siteInfo,
                    filters,
                    allowedFilters
                );
                if (urlLink.includes('dashboard')) {
                    originalUrl = originalUrl.replace(
                        /firstClassifiers/g,
                        'firstclassifiers'
                    );
                    originalUrl = originalUrl.replace(
                        /secondClassifiers/g,
                        'secondclassifiers'
                    );
                    originalUrl = originalUrl.replace(
                        /temporality/g,
                        'temporality'
                    );
                    originalUrl = originalUrl.replace(
                        /startDate/g,
                        'startdate'
                    );
                    originalUrl = originalUrl.replace(/endDate/g, 'enddate');
                    originalUrl = originalUrl.replace(
                        /idCompanies/g,
                        'idcompanies'
                    );
                    originalUrl = originalUrl.replace(
                        /idOrganization/g,
                        'idorganization'
                    );
                    originalUrl = originalUrl.replace(/idAgents/g, 'idagents');
                    originalUrl = originalUrl.replace(
                        /macroProject/g,
                        'macroproject'
                    );
                    originalUrl = originalUrl.replace(
                        /lvpEstadoInmueble/g,
                        'lvpestadoinmueble'
                    );
                    originalUrl = originalUrl.replace(
                        /lvpEstadoPosventa/g,
                        'lvpestadoposventa'
                    );
                    originalUrl = originalUrl.replace(
                        /ariasOrigenPosventa/g,
                        'ariasorigenposventa'
                    );
                    originalUrl = originalUrl.replace(
                        /responseStates/g,
                        'responsestates'
                    );
                    originalUrl = originalUrl.replace(
                        /onVacations/g,
                        'onvacations'
                    );
                }
                return originalUrl;
            })() +
            '#bordered=false&titled=false&hide_parameters=' +
            hiddenFilters;
        return originalUrl;
    };
    if (urlLink !== '') {
        return (
            <React.Fragment>
                <Filters
                    mobile={mobile}
                    allowedFilters={allowedFilters}
                    filters={filters}
                    setFilters={setFilters}
                />
                <div className={styles.iframeContainer}>
                    <iframe
                        src={formatUrl()}
                        width="100%"
                        frameBorder="0"
                        className={
                            mobile
                                ? styles.mobileIframeStyles
                                : styles.iframeStyles
                        }
                        onLoad={(): void => {
                            setIframeLoaded(true);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}

export default FrameWithFilters;
